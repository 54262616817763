<template>
  <v-container id="company_mgr-tables" fluid tag="section">
    <base-material-card icon="mdi-clipboard-text" :title="$t('company_mgr.title')" class="px-5 py-3">
      <v-col class="text-right">
        <v-dialog v-model="dialog_form" max-width="500px" @click:outside="close">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on" @click="createCompany">
              <v-icon left>mdi-pencil-plus</v-icon>
              {{ $t("company_mgr.createCompany") }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline" v-if="newCompany">{{ $t("company_mgr.createCompany") }}</span>
              <span class="headline" v-if="!newCompany">{{ $t("company_mgr.updateCompany") }}</span>
            </v-card-title>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text>
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.name"
                        :rules="fieldRules"
                        :label="$t('company_mgr.name')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.company_id"
                        :rules="fieldRules"
                        :label="$t('company_mgr.ID')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.phone"
                        :rules="fieldRules"
                        :label="$t('company_mgr.phone')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.max_urban_renewal"
                        :label="$t('user_mgr.max_urban_renewal')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.max_issue"
                        :label="$t('user_mgr.max_issue')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-list
                        subheader
                      >
                        <v-subheader class="v-label theme--light">
                          {{ $t('user_role.CompanyAdmin') }}
                        </v-subheader>
                        <v-divider inset></v-divider>
                        <v-list-item v-for="admin in item.admins" :key="admin.id">
                          <v-list-item-content class="py-0">
                            <v-list-item-title v-text="admin.username"></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action class="ma-0 flex-row">
                            <v-btn color="orange" small @click="setCompanyUser(admin.id)">
                              {{ $t('company_mgr.setUser') }}
                            </v-btn>
                            <v-btn color="red" class="mx-1" small
                                @click="removeUser(admin.id)"
                                >{{ $t("common.delete") }}
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-subheader class="v-label theme--light">
                          {{ $t('user_role.CompanyUser') }}
                        </v-subheader>
                        <v-divider inset></v-divider>
                        <v-list-item v-for="user in item.users" :key="user.id">
                          <v-list-item-content class="py-0">
                            <v-list-item-title v-text="user.username"></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action class="ma-0 flex-row">
                            <v-btn color="ocean" small @click="setCompanyAdmin(user.id)">
                              {{ $t('company_mgr.setAdmin') }}
                            </v-btn>
                            <v-btn color="red" class="mx-1" small
                                @click="removeUser(user.id)"
                                >{{ $t("common.delete") }}
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{
                $t("common.cancel")
                }}
              </v-btn>
              <v-btn v-if="newCompany" color="blue darken-1" text :disabled="loading" @click="save">
                {{
                $t("common.save")
                }}
              </v-btn>
              <v-btn v-if="!newCompany" color="blue darken-1" text @click="update">
                {{
                $t("common.save")
                }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.actions="{ item }">
          <v-flex>
            <v-btn
              color="primary"
              class="mx-2"
              small
              @click="showDetail(item)"
            >{{ $t("common.profile") }}</v-btn>
            <v-btn color="red" class="mx-2" small
              @click="removeCompany(item)"
              >{{
                $t("common.delete")
            }}</v-btn>
          </v-flex>
        </template>
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-data-footer
            dense
            :show-first-last-page="footerProps.showFirstLastPage"
            :show-current-page="footerProps.showCurrentPage"
            :first-icon="footerProps.firstIcon"
            :last-icon="footerProps.lastIcon"
            :prev-icon="footerProps.prevIcon"
            :next-icon="footerProps.nextIcon"
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            :itemsPerPageOptions="footerProps.itemsPerPageOptions"
          />
        </template>
        <template slot="no-data">
          <div>
            {{ $t('common.noData') }}
          </div>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
export default {
  data: vm => ({
    dialog_message: false,
    valid: true,
    items: [],
    totalItems: 0,
    loading: false,
    dialog_form: false,
    options: {},
    newCompany: true,
    companyId: null,
    message: {
      title: "",
      text: ""
    },
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("company_mgr.name"),
        value: "name"
      },
      {
        sortable: false,
        text: vm.$i18n.t("company_mgr.ID"),
        value: "company_id"
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: '',
        value: "actions"
      }
    ],
    item: {
      name: "",
      company_id: "",
      phone: "",
      max_urban_renewal: 0,
      max_issue: 0,
      admins: [],
      users: [],
    },
    fieldRules: [v => !!v || vm.$i18n.t("common.missing")],
  }),
  components: {
    datetime: Datetime
  },
  computed: {
    ...mapState(["token"])
  },
  methods: {
    ...mapMutations(["setTitle"]),
    close() {
      this.dialog_form = false;
      this.loading = false;
    },
    createCompany() {
      this.$refs.form.resetValidation()
      this.newCompany = true;
      this.item.name = "";
      this.item.phone = "";
      this.item.company_id="";
      this.item.max_urban_renewal = 0
      this.item.max_issue = 0
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/company/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
        params: {
            page: page,
            page_size: itemsPerPage,
          //   task: this.id
        }
      };

      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    getCompany(){
        this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/company/" + this.companyId + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        },
      };

      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setCompany(response.data);
          console.log(response.data)
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = info.count;
      this.items = data.results;
    },
    setCompany(data){
      this.item.name = data.company.name;
      this.item.company_id = data.company.company_id;
      this.item.phone = data.company.phone;
      this.item.max_urban_renewal = data.company.max_urban_renewal;
      this.item.max_issue = data.company.max_issue;
      this.item.admins = data.admin;
      this.item.users = data.user;
    },
    showDetail(company) {
      this.dialog_form = true;
      this.newCompany = false;
      console.log(company)
      this.companyId = company.id;
      this.getCompany()
    },
    setCompanyAdmin(user_id){
      let user_index = this.item.users.findIndex(user=>{ return user.id == user_id })
      this.item.admins.push(this.item.users[user_index])
      this.item.users.splice(user_index,1)
    },
    setCompanyUser(user_id){
      let user_index = this.item.admins.findIndex(user=>{ return user.id == user_id })
      this.item.users.push(this.item.admins[user_index])
      this.item.admins.splice(user_index,1)
    },
    checkAdmin(){
      let valid = true
      if(this.item.admins.length==0){
        valid = false
        alert(this.$i18n.t('company_mgr.noAdmin'))
      }
      return valid
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/company/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token
        }
      };
      let data = {
        name: this.item.name,
        company_id: this.item.company_id,
        phone: this.item.phone,
        max_urban_renewal: this.item.max_urban_renewal,
        max_issue: this.item.max_issue,
      };
      this.axios
        .post(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.getApi();
          currentObj.close();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let adminValid = this.checkAdmin()
      if(!adminValid){
        return
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/company/" + this.companyId + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token
        }
      };
      let data = {
        name: this.item.name,
        company_id: this.item.company_id,
        phone: this.item.phone,
        admin: this.item.admins,
        user: this.item.users,
        max_urban_renewal: this.item.max_urban_renewal,
        max_issue: this.item.max_issue,
      };
      this.axios
        .patch(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.getApi();
          currentObj.close();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    removeCompany(item){
      if(!confirm(
          this.$i18n.t('common.confirm') + this.$i18n.t('common.delete')+ "？"
        )
      ) {
        return;
      }
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL +
        "/backend/company/" +
        item.id +
        "/remove/";
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };
      this.axios
        .patch(url, {}, config)
        .then(function(response) {
          alert("刪除成功。");
          currentObj.getApi();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    checkIfAdmin(user_id){
      let valid = true
      let user_index = this.item.admins.findIndex(user=>{ return user.id == user_id })
      if(user_index != -1 && this.item.admins.length==1){
        valid = false
        alert(this.$i18n.t('company_mgr.noAdmin'))
      }
      return valid
    },
    removeUser(user_id) {
      if(!confirm(
          this.$i18n.t('common.confirm') + this.$i18n.t('common.delete')+ "？"
        )
      ) {
        return;
      }
      let adminValid = this.checkIfAdmin(user_id)
      if(!adminValid){
        return
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/company/" + this.companyId + "/removeCompanyUser/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token
        }
      };
      let data = {
        user_id: user_id,
      };
      this.axios
        .patch(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("刪除成功");
          currentObj.getCompany();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t("company_mgr.title"))
    this.getApi();
  }
};
</script>

<style lang='sass' scope>
.v-form
  width: 100%
  .v-btn__content
    a
      color: #FFFFFF
      text-decoration: none

</style>